import {
  Component,
  ViewChild,
  Renderer2,
  ElementRef,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import {
  NavController,
  Platform,
  AlertController,
  ModalController,
} from '@ionic/angular';
import { DrawerState } from 'ion-bottom-drawer';
import { GlobalVariable } from './global';
import { LocalStorageService } from './services/core/local-storage.service';
import { UserApiService } from './services/user-api.service';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { CONFIG } from './services/app-config.service';
import { RouterOutlet, ActivationStart } from '@angular/router';
import { environment } from 'src/environments/environment';
import { OrderTypeSelectionPage } from '../app/order-type-selection/order-type-selection.page';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild(RouterOutlet) outlet: RouterOutlet;
  dockedHeight: 50;
  shouldBounce: true;
  disableDrag: false;
  distanceTop: 0;
  transition: '0.25s';
  state: DrawerState.Docked;
  minimumHeight: 60;
  places: any;
  queryParam: any = null;
  constructor(
    private platform: Platform,
    private router: Router,
    private navCTRL: NavController,
    private localStorageService: LocalStorageService,
    public globals: GlobalVariable,
    public server: UserApiService,
    public statusbar: StatusBar,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private el: ElementRef,
    private alertCtrl: AlertController,
    public modalCtrl: ModalController
  ) {
    this.initializeApp();
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Store the previous route when navigation starts
        let route = this.router.url;
        localStorage.setItem('prevRoute', route);
      }
    });
  }

  initializeApp() {
    //to turn off all logs (useful in web ) added on 29 aug by rameez
    // if(environment.production){
    //   window.console.log=()=>{};
    // }

    let queryParam = null;
    this.route.queryParams.subscribe((params) => {
      const param1 = params['order_from'];
      console.log('Param1:', param1);
      if (param1 == 'school') {
        queryParam = { order_from: param1 };
        this.queryParam = queryParam;
        sessionStorage.setItem('orderFrom', param1);

        let popVal = JSON.parse(sessionStorage.getItem('showInfoPopup'));

        console.log('param', popVal);

        if (popVal === false) {
        } else {
          sessionStorage.setItem('showInfoPopup', JSON.stringify(true));
        }
      }
    });

    console.log('hostname    ', window.location.hostname);
    this.globals.hostName = window.location.hostname;
    if (this.globals.hostName != 'localhost') {
      this.globals.inWeb = true;

      this.getHostData();
    } else {
      // this.getWelcomeBanners();
      this.getbusiness();
    }
    let width = window.innerWidth;
    console.log('device width', width);
    if (width > 1199) {
      this.globals.phoneView = false;
    }

    var x = navigator.onLine;
    console.log('Online is ', x);
    if (x != true) {
      this.globals.presentToast('Offline');
    }
    if (!this.globals.inWeb) {
      this.navCTRL.navigateRoot('splash');
    }
    // this.router.navigate(['splash'])
    this.platform.ready().then(() => {
      this.statusbar.overlaysWebView(false);
      if (window.statusbar) {
        this.statusbar.show();
      }
      this.getTheme();
    });
  }
  getTheme() {
    let isDarkTheme = localStorage.getItem('darktheme');
    if (isDarkTheme == 'true') {
      document.body.classList.toggle('dark', true);
      this.statusbar.backgroundColorByHexString('#000000');
      this.statusbar.styleLightContent();
    } else {
      document.body.classList.toggle('light', true);
      this.statusbar.backgroundColorByHexString('#ffffff');
      this.statusbar.styleDefault();
    }
  }
  //for web
  async getHostData() {
    CONFIG.business_id = '';

    this.server.getHostInfo().subscribe(async (res) => {
      console.log('data from host api   ', res);
      this.globals.webMetaData = res;
      CONFIG.business_id = res.business_id;
      CONFIG.business_username = res.username;
      CONFIG.splash_image = res.logo;
      CONFIG.splash_text = res.name;
      this.globals.header_data = res?.header_data;
      this.globals.footer_data = res?.footer_data;

      this.getbusiness();
      // this. res.id
    });
  }
  getWelcomeBanners() {
    this.server.welcomeBanners().subscribe((res) => {
      console.log('welcome banner', res);
      this.globals.welcomeTitle = res.welcome_screen_title;
      this.globals.welcomeSubtitle = res.welcome_screen_subtitle;
      this.globals.banner_image =
        'url(' + res.welcome_screen_image + ') 100% 100%/100%';
    });
  }
  getbusiness() {
    this.globals.tempID = CONFIG.business_id;
    //13/sept
    let remember = localStorage.getItem('remember_branch');
    if (remember === 'true') {
      console.log('remember branch............');
      let busID = localStorage.getItem('activeBranchid');
      if (busID != null && busID != 'null') {
        console.log('saved branch id');
        CONFIG.business_id = busID;
        this.globals.branch_enabled = 0;
      }
    }

    //13/sept

    this.server.getBusiness().subscribe((res) => {
      console.log(res, 'business rec');
      if (res.status == 'true') {
        //setting business
        this.globals.businessData = res.results[0];

        this.globals.businessName = this.globals.businessData.name;
        this.globals.businessId = this.globals.businessData.business_id;
        this.globals.qrEnabled = this.globals.businessData?.app_qr;
        if (this.globals.qrEnabled == undefined) {
          this.globals.qrEnabled = true;
        }
        console.log('app qr', this.globals.qrEnabled);
        this.globals.point_check = this.globals.businessData.points_enabled;
        this.globals.MenuStyle = this.globals.businessData.menu_style;
        this.globals.punch_check = this.globals.businessData.punches_enabled;
        this.globals.special_offer = this.globals.businessData.special_offer;
        this.globals.events_enabled = this.globals.businessData.events_enabled;
        this.globals.dineInEnabled = this.globals.businessData.dine_in;
        console.log('dine in sett');
        this.globals.gallery_enabled =
          this.globals.businessData.gallery_enabled;
        this.globals.pickup = this.globals.businessData.pickup;
        this.globals.franchise_enabled =
          this.globals.businessData.franchise_enabled;
        this.globals.b_type = this.globals.businessData.b_type;
        this.globals.businessHost = this.globals.businessData.marketing_url[0];
        this.globals.latitude = this.globals.businessData.latitude;
        this.globals.longitude = this.globals.businessData.longitude;
        this.globals.menu_ready_msg =
          this.globals.businessData.menu_ready_message;
        this.globals.hours_operation =
          this.globals.businessData.hours_operation;
        this.globals.branch_enabled = this.globals.businessData.branch_enabled;
        this.globals.giftcard_enabled =
          this.globals.businessData.giftcard_enabled;
        this.globals.b_logo = this.globals.businessData.logo;
        this.globals.home_logo = this.globals.businessData.logo;

        this.globals.business_name = this.globals.businessData.name;
        this.globals.business_phone = this.globals.businessData.phone;
        this.globals.business_address = this.globals.businessData.address;
        this.globals.StripId = this.globals.businessData.stripe_id;
        this.globals.order_instructions =
          this.globals.businessData.instructions_enabled;
        this.globals.pickup_timing = this.globals.businessData.pickup_timing;
        this.globals.delivery_timing =
          this.globals.businessData.delivery_timing;
        this.globals.business_username = this.globals.businessData.username;
        this.globals.estimated_time = this.globals.businessData.delivery_time;
        this.globals.business_discount_count = parseInt(
          this.globals.businessData.business_discount_count
        );
        this.globals.username = this.globals.businessData.username;
        this.globals.bussinessId = this.globals.businessData.business_id;
        this.globals.admin_stripe =
          this.globals.businessData.admin_stripe_enabled;
        this.globals.pickupsetting = this.globals.businessData.delivery_time;
        this.globals.tax = this.globals.businessData.tax;
        this.globals.deliveryCharges = this.globals.businessData.delivery_fee;
        this.globals.cardDeliveryFee = this.globals.businessData.card_delivery_fee;
        this.globals.pickup_Time = this.globals.businessData.pickup_time;
        this.globals.minimun_order = parseInt(
          this.globals.businessData.minimum_order
        );
        this.globals.availed_discount_count = parseInt(
          this.globals.businessData.customer_discount_availed_count
        );
        this.globals.paypalId = this.globals.businessData.paypal_id;
        this.globals.Timing = this.globals.businessData.hours_operation;
        this.globals.specific_delivery_day =
          this.globals.businessData.specific_delivery_day;
        this.globals.review_enabled = this.globals.businessData.review_enabled;
        this.globals.delivery_day = this.globals.businessData.delivery_day;
        this.globals.authorize_enabled =
          this.globals.businessData.authorize_enabled;
        this.globals.card_enabled = this.globals.businessData.card_enabled;
        this.globals.admin_stripe_enabled =
          this.globals.businessData.admin_stripe_enabled;
        this.globals.catering_enabled =
          this.globals.businessData.catering_enabled;
        this.globals.catering_cart_enabled =
          this.globals.businessData.catering_cart_enabled;
        this.globals.giftcard_amount_limit =
          this.globals.businessData.giftcard_limit;
        this.globals.business_type = this.globals.businessData.business_type;
        this.globals.appointment_enabled =
          this.globals.businessData.appointment_enabled;
        this.globals.website = this.globals.businessData.website;
        this.globals.orders_enabled = this.globals.businessData.orders_enabled;
        this.globals.BusinessDiscount = parseInt(
          this.globals.businessData.discount
        );
        this.globals.ccFeeDisclaimer =
          this.globals.businessData.ccFeeDisclaimer;
        this.globals.tip_enabled = this.globals.businessData.tip_enabled;
        this.globals.menu_ready = this.globals.businessData.menu_ready;
        this.globals.delivery_time = this.globals.businessData.delivery_time;
        this.globals.doordashEnabled =
          this.globals.businessData.doordash_enabled;
        this.globals.loyaltyEnabled = this.globals.businessData.loyalty;
        this.globals.reservationEnabled = this.globals.businessData.reservation;

        // platform fee data;
        this.globals.platformFeeEnabled =
          this.globals.businessData.platformFeeEnabled;
        this.globals.platformFeeAmount =
          this.globals.businessData.platformFeeAmount;
        this.globals.platformFeeLabel =
          this.globals.businessData.platformFeeLabel;
        //scheduling
        this.globals.schedule_enabled =
          this.globals.businessData.schedule_enabled;
        // display_menu
        this.globals.display_menu = this.globals.businessData.display_menu;

        document.getElementById('title').innerHTML =
          this.globals.businessData.name;
        //  setting favicon
        const favicon = document.getElementById('favicon_main');
        favicon.setAttribute('href', this.globals.b_logo);

        if (this.globals.pickup == '1') {
          this.globals.pickup = true;
        } else {
          this.globals.pickup = false;
        }

        if (this.globals.businessData.delivery == '1') {
          this.globals.delivery = true;
        } else {
          this.globals.delivery = false;
        }

        if (this.globals.businessData.cash_enabled == '1') {
          this.globals.cash_enabled = true;
        } else {
          this.globals.cash_enabled = false;
        }

        if (this.globals.pickup == '1') {
          this.globals.pickup = true;
        } else {
          this.globals.pickup = false;
        }

        if (this.globals.businessData.delivery == '1') {
          this.globals.delivery = true;
        } else {
          this.globals.delivery = false;
        }

        if (this.globals.inWeb == true) {
          // this.globals.inWeb=true;
          // console.log('web url is same.....',this.globals.webURL)
          this.getParamsFromUrl();

          this.getCategories();
        }
        this.loadBanner();
        if (this.globals.businessData.appColor) {
          console.log(
            'app coloris     ....',
            this.globals.businessData.appColor
          );
          this.globals.appColor = this.globals.businessData.appColor;
          document.documentElement.style.setProperty(
            '--ion-color-primary',
            this.globals.appColor
          );
        } else {
          // this.globals.appColor = '#000000'
        }

        // if(this.globals.branch_enabled==1){
        //   this.getbusiness();
        // }
      }
    });
  }
  loadBanner() {
    let response = this.server.LoadBanners();
    response.subscribe(
      (data) => {
        this.globals.isDataLoaded = true;

        console.log('banners', data);
        this.globals.banner_color = data.banner_color;
        this.globals.giftCard_color = data.gift_cards_color;
        this.globals.myRewards_color = data.my_rewards_color;
        this.globals.orderNow_color = data.order_now_color;
        this.globals.specialOffer_color = data.special_offer_color;
        this.globals.themeColor = data.theme_color;
        this.globals.cash_discount = data.cash_discount;
        this.globals.banners = data.data;
        this.globals.ring_image = data.ring_image;
        this.globals.cash_discount_enabled = data.cash_discount_enabled;
        this.globals.cash_discount_percentage = data.cash_discount_percentage;
        this.globals.cash_discount_value = data.cash_discount_value;
        if (data.theme_color) {
          console.log('primary color ', data.theme_color);
          this.globals.appColor = data.theme_color;

          document.documentElement.style.setProperty(
            '--ion-color-primary',
            this.globals.appColor
          );
        } else {
          // this.globals.appColor = '#000000'
        }

        this.globals.cash_discount = data.cash_discount;

        this.globals.cash_discount_enabled = data.cash_discount_enabled;
        this.globals.cash_discount_percentage = data.cash_discount_percentage;
        this.globals.cash_discount_value = data.cash_discount_value;
      },
      (error) => {
        console.log('no network', error);
        // let alert = this.alertCtrl.create({
        //     message: 'Seems like your Network is not providing service. Try recharging',
        //     buttons: ['OK']
        // })
        // alert.present();
      }
    );
  }
  getCategories() {
    console.log('getting menu');
    this.globals.superCategories = [];
    this.globals.Homecategories = [];
    // this.globals.loader();

    // this.globals.isDataLoaded = false;
    this.server.getCategories().subscribe(
      (res) => {
        this.globals.dismiss();

        // this.globals.isDataLoaded = true;
        //   this.loadingMenu=true;
        // setTimeout(() => {
        //   this.loadingMenu=false;

        // }, 3000);

        this.globals.scrollEvents = res.restaurant.app_menu;
        // console.log(this.globals.scrollEvents,'scroll setting')
        // this.globals.businessName=res.restaurant.name;
        // this.globals.businessId=res.restaurant.id;
        this.globals.superCategories = res.super_categories;
        this.globals.superCategories.forEach((categories) => {
          categories.isAvailabe = this.checkTiming(categories?.hours_operation);
          categories.categories.forEach((category) => {
            // console.log(category);
            category.isAvailabe = this.checkTiming(category?.timings);
            category.items.forEach((item) => {
              // console.log('checking .....timing 1st time')
              // item.background = this.getColor();
              item.isAvailabe = this.checkTiming(item.item_timings);
            });
            // console.log(this.globals.superCategories);
          });
        });

        if (this.globals.superCategories.length > 0) {
          this.globals.Homecategories =
            this.globals.superCategories[0]?.categories;

          this.globals.activeSuperCatID =
            this.globals.Homecategories[0].category_id; //active segment purpose only

          this.globals.orderOnCall = JSON.parse(
            localStorage.getItem('orderOnCall')
          );

          if (!this.globals.queryParamsFromUrl.type) {
            let path = window.location.pathname;

            let redirect = 'order';
            if (path == '/gift-cards') {
              redirect = 'gift-cards';
            }
            this.router
              .navigate([redirect], {
                replaceUrl: true,
                queryParams: this.queryParam,
              })
              .then((res) => {
                if (this.globals.orderOnCall) {
                  this.orderTypePage();
                }
                // this.router.navigate(['tabs/tabs/menu'],{replaceUrl:true}).then(res=>{

                console.log(res, 'navigation');
                // if(!res){
                //   this.router.navigate([])
                // }
              });
          }

          // }
          // this.outlet.deactivate();
          // });

          //  }

          // this.router.navigate(['/'],{replaceUrl:true}).then(res=>{
          //   console.log(res,'navigation');
          // })

          this.time_change();
        } else {
          let path = window.location.pathname;

          let redirect = '';
          if (path === '/gift-cards') {
            redirect = 'gift-cards';
          } else {
            redirect = 'order';
          }
          this.router.navigate([redirect], {
            replaceUrl: true,
            queryParams: this.queryParam,
          });
        }

        if (this.globals.inWeb) {
          let cartData = localStorage.getItem('cart');
          console.log('items in storage', cartData);
          if (
            cartData != 'null' &&
            cartData != null &&
            cartData != 'undefined'
          ) {
            this.globals.itemsInCart = JSON.parse(cartData);
            // this.totalCalculate()
          }
        }
        // console.log('0 index super ..',this.globals.Homecategories)
      },
      (err) => {
        this.globals.dismiss();

        this.globals.isDataLoaded = true;
        this.globals.presentToast('Network Error, try again');
      }
    );
  }

  async orderTypePage() {
    let modal = await this.modalCtrl.create({
      component: OrderTypeSelectionPage,

      cssClass: 'orderTypeModal',
      mode: 'ios',
      backdropDismiss: false,
      swipeToClose: false,
    });
    modal.present();
    modal.onDidDismiss().then((data) => {
      console.log(data);
    });
  }

  checkTiming(Timing) {
    // console.log(Timing);
    if (Timing) {
      var scheduled_time_ = localStorage.getItem('scheduled_time');
      var date: any;
      var time: any;
      var day: any;
      if (
        this.globals.order_time == 'schedule' &&
        scheduled_time_ &&
        scheduled_time_ != null
      ) {
        day = this.globals.s_day;
        time = this.globals.s_time;
      } else {
        date = new Date();
        day = date.getDay();
        time = date.getHours() + '.' + date.getMinutes();
      }

      if (day == 7) {
        day = 0;
      }

      var current_day = Timing[day];
      var n = current_day[0].indexOf('.');
      if (n != -1) {
        var res = current_day[0].split('.');
        current_day[0] = res[0] + '.' + '3';
      }
      var n1 = current_day[1].indexOf('.');
      if (n1 != -1) {
        var res = current_day[1].split('.');
        current_day[1] = res[0] + '.' + '3';
      }

      time = Number(time);
      if (current_day) {
        if (
          (Number(current_day[0]) <= time && Number(current_day[1]) > time) ||
          (Number(current_day[0]) <= time &&
            Number(current_day[1]) < Number(current_day[0]))
        ) {
          return true;
        } else if (current_day[0] == 'opened' && current_day[1] == 'opened') {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      // console.log('timings not set so item availble');
      return true;
    }
  }
  time_change() {
    var scheduled_time_ = localStorage.getItem('scheduled_time');
    if (scheduled_time_ && scheduled_time_ != 'undefined') {
      let response = this.server.date_convert(scheduled_time_);
      response.subscribe(
        (data) => {
          console.log('.............', data);
          if (data.success == true) {
            this.globals.s_day = data.day_id + 1;
            this.globals.s_time = data.time;
          }
        },
        (error) => {
          this.globals.presentToast(
            'Something went wrong check your internet connection.'
          );
        }
      );
    } else {
      console.log('time not scheduled');
    }
  }

  getParamsFromUrl() {
    if (!this.globals.inWeb) {
      return;
    }
    console.log('query params', this.route.snapshot.queryParams);
    this.globals.queryParamsFromUrl = this.route.snapshot.queryParams;
  }
}
