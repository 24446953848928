/* eslint-disable no-trailing-spaces */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {
  AlertController,
  LoadingController,
  ModalController,
  Platform,
  ToastController,
} from '@ionic/angular';
// import { AppConfigService } from './services/app-config.service';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy/ngx';
import { UserApiService } from './services/user-api.service';
import { LoginModalPage } from './login-modal/login-modal.page';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { NavigationModelComponent } from './models/navigation-model/navigation-model.component';
declare let Circles;
@Injectable()
export class GlobalVariable {
  activeRewardsSegment = 'rewards';
  isloggedIn = false;
  bannerSliderImages: string[] = [];
  businessList: any;
  // CartItems=[];
  userLocation = {};
  userAddress: any;
  cartTipType: any;
  mainTip: any = 0;
  customPerTip: any;
  customTipType: string;
  isCustomTip = false;
  ProductsTotal = 0;
  isCategoryData: boolean;

  notes: any;

  businessMenuItem: any;
  businessName: any;
  businessId: any;
  paymentMethod: any;
  BaseUrl: string;
  superCategories = [];
  businessHost;
  categories = [];
  businessData: any;
  point_check: any;
  punch_check: any;
  special_offer: any;
  events_enabled: any;
  gallery_enabled: any;
  pickup: any;
  latitude: any;
  longitude: any;
  menu_ready_msg: any;
  hours_operation: any;
  branch_enabled: any;
  giftcard_enabled: any;
  b_logo: any;
  home_logo: any;
  business_name: any;
  business_phone: any;
  business_address: any;
  StripId = '';
  order_instructions: any;
  pickup_timing: any;
  delivery_timing: any;
  business_username: any;
  estimated_time: any;
  business_discount_count: any = 0;
  username: any;
  bussinessId: any;
  admin_stripe: any;
  pickupsetting: any;
  tax: any;
  deliveryCharges: any = 0;
  cardDeliveryFee: any = 0;
  pickup_Time: any;
  minimun_order: number;
  availed_discount_count: any = 0;
  paypalId: any;
  Timing: any;
  specific_delivery_day: any;
  review_enabled: any;
  delivery_day: any;
  authorize_enabled = false;
  card_enabled = false;
  admin_stripe_enabled = false;
  catering_enabled = false;
  catering_cart_enabled = false;
  giftcard_amount_limit: any = 0;
  business_type: any;
  appointment_enabled = false;
  orders_enabled: any;
  BusinessDiscount: any = 0;
  ccFeeDisclaimer: any;
  tip_enabled: any = true;
  menu_ready: any;
  itemsInCart: any[] = new Array();
  OrderType = 'pickup';
  appColor = '#007e42';
  inradius = true;

  locationSelected = false;

  reward_menu_length: any = 0;
  menu_id: any;
  menu_item_arr: any = [];
  //kiosk flag true if building for kiosk
  kiosk_flag = false;
  kiosk_udid = 'e2c40e9f0a';
  myDate: any;
  DeliveryAddress = '';
  mycoordinates: string;
  schedule_converted_time: any;
  schedule_day_id: number;
  order_time: any = 'now';
  itemInstruction: any;
  obileDiscount = 0;
  TipAdded = false;
  deliveryChargesFinishthroughPickup = false;
  delivery: boolean;
  cash_discount: any;
  cash_discount_enabled: any = false;
  cash_discount_value: any = 0;
  cash_discount_percentage: any = 0;
  retail_items_discount = 0;
  cartflag = true;
  showFabFlag: boolean;
  BusinessID: any = -1;
  udid = '';
  activeCuisineList: any;
  activeBusinessData: any;
  // Product: any[] = new Array();

  GainDiscount: any = 0;

  GainDiscountFlag = false;
  BusinessDiscountFlag = false;
  BirthdayDiscount: number;
  BirthdayCreditExist: boolean;

  OrderId: any;

  birthdayAmount: any;

  MenuUrl: string;
  RadeemUrl: string;

  savePickupTime: any;

  HomeFlag: boolean;
  WallletLock = false;
  BirthdayCreditUtlized = false;
  MobileDiscount = 0;
  MobileDiscountFlag: boolean;
  code: any;

  walletPageFlag = false;
  homePageFlag = false;
  title: any;
  showbackButton = false;
  category_name: any;

  points_availed: any = 0;
  reservation_id: any;
  type: any;
  punchCount: any = 0;
  RewardCount: any = 0;
  BirthCount: any = 0;
  PointCount: any = 0;
  currentUser: any;

  TotalbadgeValue: any = 0;
  MinValue: any;
  DeliveryTime: any;

  RewardsPos: any;
  Email: any;
  PhoneNo: any;
  phone_verified: boolean;
  email_verified: boolean;

  jackemial: any;
  // new_id = AppConfigService.CONFIG.business_id;
  // main_id = CONFIG.business_id;
  // marketPlace = CONFIG.marketPlace;
  // company = CONFIG.company;
  // env = CONFIG.env;
  model_flag = true;
  signupData: any;
  percent: any;

  save_check = false;
  points_: any = 0;
  cash_enabled: boolean;

  firstName: any;
  lastName: any;

  android_url: any;
  ios_url: any;
  update_message: any;
  app_version = '2.1';
  notifications = [];
  guess_login = false;
  banner_image: any;
  punch_: any;
  punch_limit_: any;

  business_list: any;

  is_birthday: any;
  is_anniversary: any;
  address = '';
  itemDetail: any;
  cuisineID: any;
  banner_color: any;
  giftCard_color: any;
  myRewards_color: any;
  orderNow_color: any;
  specialOffer_color: any;
  themeColor: any = '#000000';
  delivery_fee: any;
  rewardTemp = false;

  selected_business_id: any;
  activeCreditCard: any;
  activeCuisine: any;
  activeCuisineName: any;
  logs = true;
  locationAlert_title = 'Location is turned off';
  locationAlert_text =
    'Please consider enabling the location for this app in order to maximize the user experience. You may still proceed without enabling the location.';
  userlatitude: number;
  userlongitude: number;
  paymentPageData;
  // : {
  //   subtotal: any;
  //   ccFee: any;
  //   giftcard: any[];
  //   amount: any;
  //   tip: any;
  //   notes: string;
  //   RewardAvailed: any;
  //   BirthdayCreditavailed: any;
  //   tax: any;
  // };
  isDataLoaded = false;
  banners = [];
  scheduled_pickup_time: any;
  ring_image: any;
  loyaltySegment = 'points';
  LocationEnabled = false;
  delivery_time: any;
  bookingCheck: boolean;
  object: any;
  selectedCategory: any;
  showPromoModal = true;
  loading: any;
  activeSuperCatID: any[];
  takeMeToCart = false;
  Homecategories = [];
  hostName: string;
  selected_branch_name: any;
  schedule_enabled = true;
  branchUsername: any;
  website: any;
  tempID: string;
  indexArray = [];
  isLoading = false;
  welcomeSubtitle: any;
  welcomeTitle: any;
  couponAmount: any = 0;
  couponType = '';
  couponId: any = '';
  subsidy: any = 0;
  webURL: string;
  doordashEnabled = false;
  giftcardData: any = {
    amount: 0,
    id: '',
  };
  s_day: any;
  s_time: any;
  scrollEvents: any;
  qrEnabled = true;
  guestLogin = false;
  itemInterval;
  rememberBranch = false;

  franchise_enabled: any;
  b_type: any;

  cateringNumberOfPersons: number;
  loyaltyEnabled: any;
  reservationEnabled = false;
  MenuStyle: any;
  phoneView = true;
  tempPlace: any;
  webMetaData: any;
  platformFeeEnabled: any;
  platformFeeAmount: any;
  platformFeeLabel: any;
  queryParamsFromUrl: any;
  dine_in: boolean;
  dineInTable: any;
  selectedDineInTable: any;
  selectedDineInRoom: any;
  holdOrdersLength = 0;
  showHoldOrderComponent = false;
  inWeb = true;
  dineInEnabled = false;
  holdOrders: any = [];
  display_menu = true;
  menuTime: any;
  header_data: any;
  footer_data: any;
  orderOnCall = false;
  constructor(
    private alertController: AlertController,
    private loadingController: LoadingController,
    private toastController: ToastController,
    private androidPermissions: AndroidPermissions,
    private locationAccuracy: LocationAccuracy,
    public platform: Platform,
    public modalCtrl: ModalController,
    public aib: InAppBrowser
  ) {
    // this.webURL = 'localhost'; //used for social sharing disabled for browser. change if changing domain
    console.log('base url', this.BaseUrl);
    // this.BaseUrl="http://192.168.1.214/mknx-apis/index.php/"; //locals
    // this.BaseUrl = 'https://mknxapi.com/api/index.php/'; //prod
    // this.BaseUrl = 'https://staging.onlineordering.mikronexus.com/index.php/'; //staging
    // for testing any bug fixing
    // this.BaseUrl = 'https://dev-v1.mknxapi.com/api/index.php/';
    // base url
    this.BaseUrl = 'https://dev.mknxapi.com/api/index.php/';

  }
  getTimeZone(){
    return '?timezone='+ Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  removeItemFromArray(ArrayName = [], data: any) {
    ArrayName.forEach((item, index) => {
      if (item === data) {ArrayName.splice(index, 1);}
    });
  }
  openHome() {
    window.open('https://' + this.businessData.website, '_blank');
  }
  async loader() {
    this.isLoading = true;
    return await this.loadingController
      .create({
        message: '<img src="assets/animation/loader2.svg" alt="loading...">',
        cssClass: 'custom_loading',
      })
      .then((a) => {
        a.present().then(() => {
          console.log('Loader Presented');
          if (!this.isLoading) {
            a.dismiss().then(() => console.log('abort presenting'));
          }
        });
      });
  }

  async dismiss() {
    this.isLoading = false;
    return await this.loadingController
      .dismiss()
      .then(() => console.log('LoaderEnd dismissed'));
  }

  async Loader() {
    this.loading = await this.loadingController.create({
      message: '<img src="assets/animation/loader2.svg" alt="loading...">',
      cssClass: 'custom_loading',

      // showBackdrop: false,
      // spinner: 'hide',
    });
    console.log(this.loading, 'LoaderStart');
    return await this.loading.present();
  }
  async loginmodal() {
    const modal = await this.modalCtrl.create({
      component: LoginModalPage,
      componentProps: { data: {} },
      // initialBreakpoint: 0.4,
      // breakpoints: [0,0.3, 0.4, 0.8, 1],
      // backdropDismiss: false,
      cssClass: 'loginModal',
      animated: true,
      // swipeToClose: true,
      mode: 'ios',
      // handle: false,
    });

    await modal.present();
    modal.onDidDismiss().then((data) => {
      console.log('login modal', data);
    });
  }

  async navigationModel() {
    const modal = await this.modalCtrl.create({
      component: NavigationModelComponent,
      componentProps: { data: {} },
      // initialBreakpoint: 0.4,
      // breakpoints: [0,0.3, 0.4, 0.8, 1],
      // backdropDismiss: false,
      cssClass: 'loginModal',
      animated: true,
      // swipeToClose: true,
      mode: 'ios',
      // handle: false,
    });

    await modal.present();
    modal.onDidDismiss().then((data) => {
      console.log('login modal', data);
    });
  }
  getColor(id, index) {
    console.log(this.isInArray(id, this.indexArray));
    // console.log(id)

    // if( id!=this.itemCustomId){
    // console.log(this.indexArray, this.indexArray.includes(id))
    // this.indexArray.map(element=>{
    //   if(element==id){
    //     console.log('id matched')
    //     return
    //   }
    //   else{
    if (this.isInArray(id, this.indexArray) == true) {
      //  return
    } else {
      // console.log('func triggered')
      const symbols = '0123456789ABCDEF';

      let color = '#';
      for (let i = 0; i < 6; i++) {
        color = color + symbols[Math.floor(Math.random() * 16)];
      }

      // this.Randomcolor = color
      // console.log(this.Randomcolor)
      // this.itemCustomId=id
      this.indexArray.push(id);
      return color;
    }
    // }

    // })
    // if(!this.indexArray.includes(id)){

    // }
    // }
  }
  isInArray(value, array) {
    return array.indexOf(value) > -1;
  }
  setupBusiness(business) {
    this.selected_business_id = business.business_id;
    this.point_check = business.points_enabled;
    this.punch_check = business.punches_enabled;
    this.special_offer = business.special_offer;
    this.events_enabled = business.events_enabled;
    this.dineInEnabled = business.dine_in;
    this.gallery_enabled = business.gallery_enabled;
    this.MenuStyle = business.menu_style;

    this.pickup = business.pickup;
    this.latitude = business.latitude;
    this.longitude = business.longitude;
    this.menu_ready_msg = business.menu_ready_message;
    this.hours_operation = business.hours_operation;
    this.branch_enabled = business.branch_enabled;
    this.giftcard_enabled = business.giftcard_enabled;
    this.b_logo = business.logo;
    this.home_logo = business.logo;
    //new added
    this.qrEnabled = business?.app_qr;
    if (this.qrEnabled == undefined) {
      this.qrEnabled = true;
    }
    //new added

    this.business_name = business.name;
    this.business_phone = business.phone;
    this.business_address = business.address;
    this.StripId = business.stripe_id;
    this.order_instructions = business.instructions_enabled;
    this.pickup_timing = business.pickup_timing;
    this.delivery_timing = business.delivery_timing;
    this.business_username = business.username;
    this.estimated_time = business.delivery_time;
    this.loyaltyEnabled = business.loyalty;
    this.business_discount_count = parseInt(business.business_discount_count);
    this.username = business.username;
    this.bussinessId = business.business_id;
    this.admin_stripe = business.admin_stripe_enabled;
    this.pickupsetting = business.delivery_time;
    this.tax = business.tax;
    this.deliveryCharges = business.delivery_fee;
    this.cardDeliveryFee = business.card_delivery_fee;
    this.pickup_Time = business.pickup_time;
    this.minimun_order = parseInt(business.minimum_order);
    this.availed_discount_count = parseInt(
      business.customer_discount_availed_count
    );
    this.paypalId = business.paypal_id;
    this.Timing = business.hours_operation;
    this.specific_delivery_day = business.specific_delivery_day;
    this.review_enabled = business.review_enabled;
    this.delivery_day = business.delivery_day;
    this.authorize_enabled = business.authorize_enabled;
    this.card_enabled = business.card_enabled;
    this.admin_stripe_enabled = business.admin_stripe_enabled;
    this.catering_enabled = business.catering_enabled;
    this.catering_cart_enabled = business.catering_cart_enabled;
    this.giftcard_amount_limit = business.giftcard_limit;
    this.business_type = business.business_type;
    this.appointment_enabled = business.appointment_enabled;
    this.orders_enabled = business.orders_enabled;
    this.BusinessDiscount = business.discount;
    this.ccFeeDisclaimer = business.ccFeeDisclaimer;
    this.tip_enabled = business.tip_enabled;
    this.menu_ready = business.menu_ready;
    this.doordashEnabled = business.doordash_enabled;
    this.schedule_enabled = business.schedule_enabled;
    this.display_menu = business.display_menu;

    if (this.pickup == '1') {
      this.pickup = true;
    } else {
      this.pickup = false;
    }

    if (business.delivery == '1') {
      this.delivery = true;
    } else {
      this.delivery = false;
    }

    if (business.cash_enabled == '1') {
      this.cash_enabled = true;
    } else {
      this.cash_enabled = false;
    }

    if (this.pickup == '1') {
      this.pickup = true;
    } else {
      this.pickup = false;
    }

    if (business.delivery == '1') {
      this.delivery = true;
    } else {
      this.delivery = false;
    }
  }
  removeAllItemsFromArray(ArrayName: Object[] = []) {
    ArrayName = ArrayName.filter((item) => item !== item);
  }
  circle_graph(value, id, radius, width, color) {
    const myCircle = Circles.create({
      id,
      radius,
      value,
      maxValue: 100,
      width,
      text(value) {
        return '';
      },
      colors: [color, '--ion-color-primary'],
      duration: 400,
      wrpClass: 'circles-wrp',
      textClass: 'circles-text',
      valueStrokeClass: 'circles-valueStroke',
      maxValueStrokeClass: 'circles-maxValueStroke',
      styleWrapper: true,
      styleText: true,
    });
  }
  checkGPSPermission() {
    //was causing error in ios .. plugin not installed by rameez
    if (this.platform.is('android')) {
      this.androidPermissions
        .checkPermission(
          this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION
        )
        .then(
          (result) => {
            if (result.hasPermission) {
              this.LocationEnabled = true;
              console.log('check 001');
              //If having permission show 'Turn On GPS' dialogue
              this.askToTurnOnGPS();
            } else {
              console.log('check 002');

              //If not having permission ask for permission
              this.requestGPSPermission();
            }
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }

  disableBtn(obj, timing?) {
    this.object = obj;
    let duration;
    if (timing) {
      duration = timing;
    } else {
      duration = 5000;
    }
    const that = this;
    const clearKey = setInterval(function() {
      that.object = '';
      console.log('disableBtn', that.object);
      clearInterval(clearKey);
    }, duration);
  }
  requestGPSPermission() {
    this.locationAccuracy.canRequest().then(
      (canRequest: boolean) => {
        if (canRequest) {
          console.log('4');
        } else {
          //Show 'GPS Permission Request' dialogue
          this.androidPermissions
            .requestPermission(
              this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION
            )
            .then(
              () => {
                // call method to turn on GPS
                this.askToTurnOnGPS();
              },
              (error) => {
                //Show alert if user click on 'No Thanks'
                console.log(
                  'requestPermission Error requesting location permissions ' +
                    error
                );
                // alert('requestPermission Error requesting location permissions ' + error)
              }
            );
        }
      },
      (err) => {
        console.log('error in request GPS Permission', err);
      }
    );
  }

  askToTurnOnGPS() {
    this.locationAccuracy
      .request(this.locationAccuracy.REQUEST_PRIORITY_BALANCED_POWER_ACCURACY)
      .then(
        (data) => {
          console.log('check 006', data);
          // When GPS Turned ON call method to get Accurate location coordinates
        },
        (error) => console.log('Error requesting location permissions', error)
        // alert('Error requesting location permissions ' + JSON.stringify(error))
      );
  }

  // addItemToArray(){

  // }
  async alertSimple(Header: any, Message: string, ButtonText: string) {
    const alert = await this.alertController.create({
      header: Header,
      message: Message,
      buttons: [ButtonText],
    });
    await alert.present();
  }
  async loadingGlobal(Message: any) {
    const loading = await this.loadingController.create({
      message: Message,
    });
    await loading.present();
  }
  async presentToast(msg, position?, duration?) {
    // if (!position) {
    //   position = 'bottom';
    // }
    const fixedDuration = 2000;

    const toast = await this.toastController.create({
      animated: true,
      position: position ? position : 'middle',
      // color: 'primary',
      cssClass: 'APP_toast',
      color: 'dark',
      buttons: [
        {
          text: '',
          side: 'end',
          icon: 'close',
          cssClass: 'cancelBTN',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
      ],

      duration: duration ? duration : fixedDuration,
      message: msg,
    });
    toast.present();
  }

  financial(x) {
    if (x != 0) {
      return Number.parseFloat(x).toFixed(2);
    } else {
      return '0.00';
    }
  }

  opneReview() {
    this.aib.create(
      'https://www.google.com/search?kgmid=/g/11gg740b7s&hl=en-PK&q=Hooked&kgs=488dac25519c2f79&shndl=30&shem=lose&source=sh/x/kp/osrp/m5/1#lrd=0x89ef54a819515555:0xdc0562632a8de19c,1',
      '_system'
    );
  }
}
